<template>
  <section class="conditions-list">
    <div class="container">
      <div class="row">
        <div class="col-xx-lg-8 col-x-lg-8 col-lg-8 col-md-7 col-xs-12">
          <div class="conditions-list__content">
            <div class="conditions-list__item" id="conditions-1">
              <h3 class="conditions-list__title">
                <span class="conditions-list__title-number">I.</span>
                <span class="conditions-list__title-text"
                >Загальні положення</span
                >
              </h3>
              <div class="conditions-list__item-content">
                <div class="conditions-list__item-group">
                  <div class="conditions-list__text">
                    <ol>
                      <li>
                        <strong>«ТерапіяПЛЮС»</strong> (далі – «Програма» або
                        «Програма «ТерапіяПЛЮС») – програма підтримки пацієнтів
                        з гострими та хронічними захворюваннями в таких областях як кардіологія, ендокринологія,
                        нефрологія та пульмонологія.<br/>Програма підтримки пацієнтів
                        «ТерапіяПЛЮС» організована міжнародною фармацевтичною
                        компанією AstraZeneca (АстраЗенека), що на території
                        України представлена ТОВ «АстраЗенека Україна» (далі –
                        «Організатор Програми»).
                      </li>
                      <li>
                        <strong>
                          Адміністрування та технічну підтримку Програми
                          здійснює
                        </strong>
                        уповноважена незалежна третя особа, визначена компанією
                        АстраЗенека (далі – «Адміністратор Програми»).
                      </li>
                      <li>
                        <strong>Програма ТерапіяПЛЮС спрямована на:</strong>
                        підвищення інформованості пацієнтів про захворювання в
                        відповідних терапевтичних областях, зниження вартості
                        терапії лікарьскими засобами, що включені до Програми.
                      </li>
                      <li>
                        <strong
                        >Програма діє тільки на території України,</strong
                        >
                        за виключенням тимчасово окупованих територій та населених пунктів, а також територій, на яких
                        відбуваються активні бойові дії та/або надзвичайні ситуації. Програма не діє за межами території
                        України.
                      </li>
                      <li>
                        <strong
                        >Учасником Програми може стати кожна фізична
                          особа,</strong
                        >
                        якій лікарем було призначено лікарський засіб,
                        міжнародна непатентована назва якого включена у
                        Програму, за умови досягнення фізичною особою 18 років
                        та повної дієздатності. Для фізичних осіб, які не
                        досягли 18 років або не мають повної дієздатності,
                        участь у Програмі можлива лише через їх законних
                        представників.
                      </li>
                      <li>
                        <strong
                        >Програма не поширюється на інші лікарські
                          засоби,</strong
                        >
                        що прямо не включені до даної Програми, а також на
                        лікарські засоби інших компаній ніж АстраЗенека. Щоб
                        дізнатися, чи включений лікарський засіб у Програму,
                        введіть назву препарату, який був призначений Вам
                        лікарем, в поле Пошук.
                      </li>
                    </ol>
                  </div>
                  <div class="conditions-list__preparats">
                    <div class="conditions-list__preparats-title">
                      <strong
                      >Препарати, що включені до Програми ТерапіяПЛЮС</strong
                      >
                      <br/>(міжнародна непатентована назва)
                    </div>

                    <div class="conditions-list__preparats-item">
                      <div
                        class="conditions-list__preparats-item-title nef-icon"
                      >
                        Нефрологія
                      </div>
                      <div class="conditions-list__preparats-item-content">

                        <p><strong>ДАПАГЛІФЛОЗИН </strong> / 10 мг</p>


                      </div>
                    </div>

                    <div class="conditions-list__preparats-item">
                      <div
                        class="conditions-list__preparats-item-title cardiology-icon"
                      >
                        Кардіологія
                      </div>
                      <div class="conditions-list__preparats-item-content">
                        <p>
                          <strong>ДАПАГЛІФЛОЗИН</strong> / 10 МГ
                        </p>
                        <p><strong>РОЗУВАСТАТИН</strong> / 5, 10, 20, 40 МГ</p>
                        <p><strong>ТИКАГРЕЛОР</strong> / 90 МГ, 60 МГ</p>
                        <!--                        <p>-->
                        <!--                          <strong>КАНДЕСАРТАНУ ЦИЛЕКСЕТИЛ</strong> / 8, 16 МГ-->
                        <!--                        </p>-->

                      </div>
                    </div>

                    <div class="conditions-list__preparats-item">
                      <div
                        class="conditions-list__preparats-item-title endocrinology-icon"
                      >
                        Ендокринологія
                      </div>
                      <div class="conditions-list__preparats-item-content">
                        <p><strong>САКСАГЛІПТИН</strong> / 2,5 МГ, 5 МГ</p>
                        <p>
                          <strong>САКСАГЛІПТИН + МЕТФОРМІН</strong> / 2,5
                          МГ + 1000 МГ
                        </p>
                        <p>
                          <strong>САКСАГЛІПТИН + МЕТФОРМІН</strong> / 5 МГ +
                          1000 МГ
                        </p>
                        <p><strong>ДАПАГЛІФЛОЗИН</strong> / 10 МГ</p>
                        <p>
                          <strong>ДАПАГЛІФЛОЗИН + МЕТФОРМІН</strong> / 5 МГ +
                          1000 МГ
                        </p>
                        <p>
                          <strong>ДАПАГЛІФЛОЗИН + МЕТФОРМІН</strong> / 10 МГ +
                          1000 МГ
                        </p>

                      </div>
                    </div>

                    <div class="conditions-list__preparats-item">
                      <div
                        class="conditions-list__preparats-item-title pulmonology-icon"
                      >
                        Пульмонологія
                      </div>
                      <div class="conditions-list__preparats-item-content">
                        <p>
                          <strong>ФОРМОТЕРОЛ + ГЛІКОПІРОНІЙ + БУДЕСОНІД</strong> / 5 + 7,2 + 160 МКГ
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="conditions-list__text">
                    <ol start="7">
                      <li>
                        <strong>
                          Участь у Програмі є добровільною та БЕЗКОШТОВНОЮ.
                          <br/>
                        </strong>
                        Програма не передбачає сплати будь-яких платежів за
                        реєстрацію у Програмі або участь у Програмі.
                        <br/>Надання стартового пакету і картки учасника
                        Програми фізичній особі, якій лікарем було призначено
                        лікарський засіб, міжнародна непатентована назва якого
                        включена у Програму, здійюснюється безкоштовно.
                      </li>
                      <li>
                        <strong>
                          Організатор Програми залишає за собою право в
                          односторонньому порядку без будь-якого попереднього
                          повідомлення учасників програми змінювати правила та
                          умови Програми та/або правила участі у Програмі,
                          змінювати обсяг Програми та/або перелік лікарських
                          засобів, які включені у Програму, а також
                          зупиняти/призупиняти/припиняти дію Програми, виключно
                          на власний розсуд та у будь-який момент.
                        </strong>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="conditions-list__item" id="conditions-2">
              <h3 class="conditions-list__title">
                <span class="conditions-list__title-number">II.</span>
                <span class="conditions-list__title-text">
                  Умови та правила використання картки учасника Програми
                  ТерапіяПЛЮС
                </span>
              </h3>
              <div class="conditions-list__item-content">
                <div class="conditions-list__text">
                  <ol>
                    <li>
                      <strong>
                        Картка учасника Програми призначена виключно для
                        особистого використання
                        <br/>
                      </strong>
                      фізичними особами, яким лікарем був призначений(-ні)
                      лікарський засіб або засоби. Картка не може
                      використовуватися особами для цілей перепродажу та
                      будь-яких інших комерційних цілей.
                    </li>
                    <li>
                      <strong>
                        Для участі у Програмі та використання картки учасника
                        Програми обов’язкова реєстрація.
                      </strong>
                      Незареєстровані картки учасника Програми не дають
                      можливість користування Програмою. Про порядок реєстрації
                      Ви можете дізнатись більше на окремій сторінці
                      рееєстрації.
                    </li>
                    <li>
                      <strong>
                        При реєстрації у Програмі учасник Програми отримує
                        доступ до Особистого Кабінету.
                      </strong>
                      З функціями Особистого Кабінету можна ознайомитися на
                      окремій сторінці.
                    </li>
                    <li>
                      <strong
                      >Дані, що надаються особою при реєстрації у
                        Програмі,</strong
                      >
                      а також у зв’язку з Програмою, повинні бути достовірними
                      та справжніми.
                      <br/>
                      <strong>Ненадання даних або надання недостовірних</strong>
                      /несправжніх даних не дає права участі у Програмі. Рішення
                      щодо недостовірності/несправжності даних приймається
                      Адміністратором Програми. У разі виявлення Адміністратором
                      Програми даних, що є недостовірними/несправжніми, на думку
                      Адміністратора Програми, або є підозрілими, картка
                      учасника Програми може бути заблокована для користування
                      та анульована Адміністратором Програми без попередження
                      учасника Програми.
                    </li>
                    <li>
                      <strong
                      >У Програмі діє правило «один учасник – одна
                        картка».</strong
                      >
                      Це означає, що один учасник може зареєструватися у
                      Програмі лише за однією карткою та відповідно
                      користуватися однією карткою. Не дозволяється реєстрація
                      та використання більше ніж однієї картки для однієї особи.
                      У випадку виявлення реєстрацій карток учасників Програми,
                      щодо яких виникає підозра про приналежність їх одній
                      фізичній особі, Адміністратор Програми може на власний
                      розсуд без будь-якого попередження заблокувати підозрілі
                      картки для користування та/або анулювати.
                    </li>
                    <li>
                      <strong>Картка учасника Програми є персональною.</strong>
                      Картку учасника Програми не можна передавати третім
                      особам. Про знайдену чужу картку слід повідомляти
                      Адміністратора Програми.
                    </li>
                    <li>
                      <strong
                      >Про втрату картки учасника Програми або її
                        пошкодження</strong
                      >
                      слід в найкоротший можливий термін повідомляти
                      Адміністратора Програми.
                    </li>
                    <li>
                      <strong>Придбання лікарського засобу,</strong>
                      що включений у Програму, за зниженою вартістю згідно до
                      умов Програми можливе у будь-яких аптеках, які беруть
                      участь у Програмі.
                    </li>
                    <li>
                      <strong>Для придбання лікарського засобу,</strong>
                      що включений у Програму, зі знижкою згідно Програми
                      учасник Програми повинен пред’явити відповідний рецепт
                      лікаря (відповідно до чинного законодавства) та картку
                      учасника Програми. Працівник аптеки-учасниці Програми
                      повинен відсканувати штрих-код картки учасника Програми.<br>За умовами програми, придбання
                      лікарського засобу, що входить до Програми, у кількості не менш ніж 1 (одна) упаковка, забезпечує
                      можливість його придбання за зниженою вартістю (зі знижкою).

                    </li>
                    <li>
                      <strong>
                        Зниження ціни (знижка) на лікарський засіб, що бере участь у Програмі,
                      </strong>
                      розраховується від роздрібної ціни на такий лікарський засіб, яка встановлена таким аптечним
                      закладом. При цьому, аптечні заклади, керуючись чинним законодавством України, самостійно
                      встановлюють ціни на лікарські засоби, в тому числі на лікарські засоби, що включені у Програму. В
                      зв’язку з цим, абсолютний розмір знижки за Програмою, а також остаточна ціна лікарського засобу
                      після врахування знижки за Програмою, може відрізнятися у різних аптеках.
                      <br/>
                      <strong>
                        У разі, якщо в аптеці передбачені будь-які додаткові
                        знижки
                      </strong>
                      (наприклад, за програмами лояльності конкретної аптечної
                      мережі тощо), то надання таких додаткових знижок
                      залишається на розсуд відповідної аптеки. Але в будь-якому
                      разі, якщо аптека бере участь у Програмі, то надання
                      знижки, передбаченої умовами Програми, фізичній особі, яка
                      пред’явила картку учасника Програми, є обов’язковим.
                    </li>
                    <li>
                      <strong>
                        Для зниження ризиків неправомірного використання карток
                        учасників Програми
                      </strong>
                      встановлено обмеження максимальної кількості лікарських
                      засобів, що включені у Програму, яку може придбати один
                      учасник Програми, використовуючи картку учасника Програми,
                      протягом місяця у розмірі: не більше 3 (трьох) упаковок
                      одного лікарського засобу протягом місяця та при цьому не
                      більше 9 (дев’яти) упаковок усіх лікарських засобів, що
                      включені у Програму, загалом протягом місяця.
                    </li>
                    <li>
                      <strong>
                        Картка учасника Програми є власністю Організатора
                        Програми.
                      </strong>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="conditions-list__item" id="conditions-3">
              <h3 class="conditions-list__title">
                <span class="conditions-list__title-number">III.</span>
                <span class="conditions-list__title-text"
                >Припинення участі у Програмі</span
                >
              </h3>
              <div class="conditions-list__item-content">
                <div class="conditions-list__text">
                  <ol>
                    <li>
                      <strong>
                        Зареєстрований учасник Програми може за власним бажанням
                        припинити свою участь у Програмі
                      </strong>
                      шляхом відповідного запиту Адміністратору Програми. Після
                      отримання такого запиту картку учасника Програми буде
                      анульовано, а картка буде недоступною для використання.
                    </li>
                    <li>
                      <strong>
                        Участь фізичної особи у Програмі може бути припинена
                        Адміністратором Програми з
                      </strong>
                      відповідним анулюванням картки учасника Програми у разі
                      відсутності транзакцій (покупок) з використанням картки
                      учасника Програми протягом терміну більше ніж 24 (двадцять
                      чотири) місяці поспіль. Таке припинення здійснюється без
                      будь-якого попереднього повідомлення учасника Програми.
                    </li>
                    <li>
                      <strong>
                        Адміністратор Програми може в односторонньому порядку
                        припинити участь будь-якого учасника
                      </strong>
                      у Програмі з відповідним анулюванням картки учасника
                      Програми без будь-якого попередження у випадку, якщо на
                      власний розсуд та переконання Адміністратором Програми
                      визначено, що учасник Програми порушив будь-яке положення
                      Умов та Правил Програми, або що використання картки
                      учасника здійснюється з порушенням будь-якого положення
                      Умов та Правил Програми, або що використання картки
                      учасника здійснюються із зловживаннями чи з шахрайськими
                      цілями чи з цілями введення оману чи з будь-якими іншими
                      сумнівними або неправомірними цілями.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="conditions-list__item" id="conditions-4">
              <h3 class="conditions-list__title">
                <span class="conditions-list__title-number">IV.</span>
                <span class="conditions-list__title-text"
                >Обмеження відповідальності</span
                >
              </h3>
              <div class="conditions-list__item-content">
                <div class="conditions-list__text">
                  <ol>
                    <li>
                      <strong>
                        Організатор Програми та його афілійовані особи,
                        Адміністратор Програми
                      </strong>
                      за жодних умов не несуть відповідальність перед учасниками
                      Програми за будь-які збитки, втрати, упущені можливості
                      або вигоди, будь-які інші негативні наслідки, як прямі,
                      так і непрямі, що виникли у зв’язку з:
                      <ul>
                        <li>
                          — Зупиненням, тимчасовим призупиненням, припиненням
                          Програми, а так само будь-якими змінами у Програмі, її
                          правилах та умовах;
                        </li>
                        <li>
                          — Затримкою доступу або збоями/перервами у доступі до
                          Програми (в тому числі при використанні картки
                          учасника Програми, доступі до будь-яких сервісів чи
                          ресурсів Програми) для будь-якого або усіх учасників
                          Програми;
                        </li>
                        <li>
                          — Збоєм, дефектом, помилкою програмного забезпечення
                          або технічної апаратури, що впливає на можливість
                          використання Програми для будь-якого або усіх
                          учасників Програми;
                        </li>
                        <li>
                          — Неможливістю відновити інформацію, що була втрачена
                          або потенційно викривлена чи пошкоджена в результаті
                          будь-яких технічних несправностей, обставин поза
                          розумним контролем, неправомірних дій будь-яких третіх
                          осіб;
                        </li>
                        <li>
                          — Неправомірними діями будь-яких третіх Сторін,
                          зокрема, але не обмежуючись: шахрайські дії,
                          фальсифікація, тощо;
                        </li>
                        <li>
                          — Дією обставин, що знаходились поза розумним
                          контролем, включно, але не обмежуючись: стихійні лиха,
                          громадські безпорядки, воєнні дії, тощо.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>
                        Організатор Програми та/або Адміністратор Програми не
                        несуть відповідальності
                      </strong>
                      за дії аптечного закладу та/або за наявність лікарського
                      засобу у аптечному закладі.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="conditions-list__item" id="conditions-5">
              <h3 class="conditions-list__title">
                <span class="conditions-list__title-number">V.</span>
                <span class="conditions-list__title-text"
                >Персональні дані та конфіденційність</span
                >
              </h3>
              <div class="conditions-list__item-content">
                <div class="conditions-list__text">
                  <ol>
                    <li>
                      <strong>Обробка персональних даних,</strong>
                      що надаються фізичними особами у зв’язку з Програмою, здійснюється відповідно до Політики Конфіденційності Програми, яка доступна на сайті
                      <a href="https://www.terapiaplus.com/">www.terapiaplus.com.</a>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div id="conditions-6" class="conditions-list__item">
              <h3 class="conditions-list__title">
                <span class="conditions-list__title-number">VI.</span>
                <span class="conditions-list__title-text">Інше</span>
              </h3>
              <div class="conditions-list__item-content">
                <div class="conditions-list__text">
                  <ol>
                    <li>
                      <strong
                      >Програма діє до її припинення Організатором
                        Програми.</strong
                      >
                      Організатор Програми залишає за собою право в
                      односторонньому порядку без будь-якого попереднього
                      повідомлення учасників Програми змінювати правила та умови
                      Програми та/або правила участі у Програмі, змінювати обсяг
                      Програми та/або перелік лікарських засобів, які включені у
                      Програму, а також зупиняти/призупиняти/припиняти дію
                      Програми в цілому або в частині, виключно на власний
                      розсуд та у будь-який момент.
                    </li>
                    <li>
                      <strong>
                        Реєструючись у Програмі, учасники Програми надають згоду
                        на отримання комунікацій щодо Програми за контактними
                        данними такого учасника.
                      </strong>
                      Кожен учасник Програми має право в будь-який час
                      відмовитися від отримання таких комунікацій, сповістивши
                      про це Адміністратора Програми.
                      <br/>Детальніше про опції сповіщення Адміністратора
                      Програми, про бажання відписатися від отримання
                      комунікацій Ви можете дізнатися на окремій сторінці за
                      посиланням.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-xx-lg-4 col-x-lg-4 col-lg-4 col-md-5 col-xs-12"
          v-if="!getResponsive"
        >
          <div class="conditions-list__sidebar">
            <ol>
              <li>
                <span>I.</span>
                <a href="#conditions-1">Загальні положення</a>
              </li>
              <li>
                <span>II.</span>
                <a href="#conditions-2">
                  Умови та правила використання картки учасника Програми
                  ТерапіяПЛЮС
                </a>
              </li>
              <li>
                <span>III.</span>
                <a href="#conditions-3">Припинення участі у програмі</a>
              </li>
              <li>
                <span>IV.</span>
                <a href="#conditions-4">Обмеження відповідальності</a>
              </li>
              <li>
                <span>V.</span>
                <a href="#conditions-5">Персональні дані та конфіденційність</a>
              </li>
              <li>
                <span>VI.</span>
                <a href="#conditions-6">Інше</a>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ConditionsList",
  computed: {
    ...mapGetters(["getResponsive"])
  },
  mounted() {
    setTimeout(() => {

      // If not responsive width
      if (!this.getResponsive) {
        let mainNavLinks = document.querySelectorAll(
          ".conditions-list__sidebar ol li a"
        );
        mainNavLinks.forEach(link => {
          link.addEventListener("click", event => {
            event.preventDefault();
            let target = document.querySelector(event.target.hash);
            target.scrollIntoView({
              behavior: "smooth"
            });
          });
        });
        window.addEventListener("scroll", () => {
          let fromTop = window.scrollY;

          mainNavLinks.forEach(link => {
            let section = document.querySelector(link.hash);
            if (section) {
              if (
                section.offsetTop <= fromTop &&
                section.offsetTop + section.offsetHeight > fromTop
              ) {
                link.parentElement.classList.add("active");
              } else {
                link.parentElement.classList.remove("active");
              }
            }
          });
        });
      }
      // If responsive width
      else {
        // listeners for faqs
        let accordions = document.querySelectorAll(".conditions-list__title");
        accordions.forEach(accordion => {
          accordion.addEventListener("click", () => {
            if (!accordion.parentNode.classList.contains("active")) {
              accordions.forEach(accordion => {
                accordion.parentElement.classList.remove("active");
                accordion.parentElement.children[1].setAttribute(
                  "style",
                  "height:0px"
                );
              });
            }
            if (accordion.parentNode.classList.contains("active")) {
              accordion.parentElement.classList.remove("active");
              accordion.parentElement.children[1].setAttribute(
                "style",
                "height:0px"
              );
            } else {
              accordion.parentElement.classList.add("active");
              var contentHeight =
                accordion.parentElement.children[1].children[0].clientHeight;
              accordion.parentElement.children[1].setAttribute(
                "style",
                "height:" + contentHeight + "px"
              );
              setTimeout(() => {
                accordion.scrollIntoView()
              }, 350)
            }
          });
        });
      }
    }, 200)
  }
};
</script>
